declare const firebase: any;

const cache: CachedItem[] = [];
let isLoaded = false;
let errorLoadingScript = false;
let userLatitude = 0;
let userLongitude = 0;
let userDataSet = false;

export enum FireBaseFunc {
  LOG_FIREBASE_SCREEN_NAME = 'logFirebaseScreenName',
  LOG_FIREBASE_USER = 'logFirebaseUser',
  LOG_FIREBASE_EVENT = 'logFirebaseEvent',
}

interface CachedItem {
  firebaseFunc: FireBaseFunc;
  params: Array<any>;
}

interface FirebaseConfigProps {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

const loadScript = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.src = src;
    script.addEventListener('load', (event) => {
      resolve();
    });
    script.addEventListener('error', (event) => {
      reject();
    });
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  });
};

export enum FirebaseParameters {
  MODULE = 'module',
  PAGE = 'page',
  USER_ID = 'UserID',
  USER_TYPE = 'userType',
  TYPE = 'type',
  DATE = 'date',
  ITEM_LIST_COUNT = 'item_list_count',
  ID = 'id',
  DESTINATION = 'destination',
  NAME = 'name',
  LAT = 'lat',
  LNG = 'lng',
  SEARCH_NAME = 'search_name',
  PRICE = 'price',
  VALUE = 'value',
  SEARCH_TYPE = 'search_type',
  ROUTE = 'route',
  STARTING_LOCATION = 'starting_location',
  ENDING_LOCATION = 'ending_location',
  FEATURED_STATIONS = 'featured_stations',
  TYPES = 'types',
}

export enum FirebasePages {
  SEARCH = 'Search',
}

export enum FirebaseModules {
  PROVERIFY = 'ProVerify',
}

export enum FirebaseEvents {
  CLICK = 'click',
}

export const logFirebaseScreenName = (page: FirebasePages) => {
  if (cacheEvent(FireBaseFunc.LOG_FIREBASE_SCREEN_NAME, [page])) {
    return;
  }
  firebase.analytics().setCurrentScreen(page);
};

const getIpAddress = async () => {
    try {
        const response = await fetch('https://api.ipify.org?format=json', {method: 'GET',});
        const ipObj = await response.json();
        return ipObj;
    }
    catch(error) {
        console.error(error);
    }
}

interface IP {
  ip: string;
}

export const logFirebaseUser = async (
) => {
    const response = await getIpAddress() as IP| undefined;
    if (cacheEvent(FireBaseFunc.LOG_FIREBASE_USER, [response?.ip])) {
        return;
    }
    firebase.analytics().setUserProperties({ [FirebaseParameters.USER_ID]: response?.ip });
    firebase.analytics().setUserId(response?.ip);
    userDataSet = true;
};

export const logFirebaseEvent = (
  event: FirebaseEvents,
  module: FirebaseModules,
  page: FirebasePages,
  customParams: any
) => {
  if (
    cacheEvent(FireBaseFunc.LOG_FIREBASE_EVENT, [
      event,
      module,
      page,
      customParams,
    ])
  ) {
    return;
  }

  if (!userDataSet) {
    logFirebaseUser();
  }
  let mainParams = {
    module: module,
    page: page,
    userLatitude,
    userLongitude,
  };

  let allParams = Object.assign({}, customParams, mainParams);

  firebase.analytics().logEvent(event, allParams);
};

const loadCachedEvents = () => {
  if (cache.length > 0) {
    cache.forEach((e) => {
      switch (e.firebaseFunc) {
        case FireBaseFunc.LOG_FIREBASE_EVENT:
          logFirebaseEvent(e.params[0], e.params[1], e.params[2], e.params[3]);
          break;
        case FireBaseFunc.LOG_FIREBASE_SCREEN_NAME:
          logFirebaseScreenName(e.params[0]);
          break;
        case FireBaseFunc.LOG_FIREBASE_USER:
          logFirebaseUser();
          break;
      }
    });
  }
};

const getLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        userLatitude = latitude;
        userLongitude = longitude;
      },
      () => {}
    );
  }
};

export const init = async (firebaseConfig: FirebaseConfigProps) => {
  getLocation();

  await loadScript('https://www.gstatic.com/firebasejs/7.16.0/firebase-app.js');
  await loadScript(
    'https://www.gstatic.com/firebasejs/7.16.0/firebase-analytics.js'
  );

  isLoaded = true;
  firebase.initializeApp(firebaseConfig);
  loadCachedEvents();
};

const cacheEvent = (
  firebaseFunc: FireBaseFunc,
  params: Array<any>
): boolean => {
  if (isLoaded === false && errorLoadingScript === false) {
    cache.push({ firebaseFunc, params });
    return true;
  }
  // if we fail to load script we do not want
  // to cache or try to log
  if (errorLoadingScript) return true;
  return false;
};
