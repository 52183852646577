import Service from "./Service";
import { RateVerification } from "../features/rateVerification/RateVerificationTypes";
import { RATE_VERFICATION_SERVICE_URL } from "../utilites/constants";

class RateVerficationService extends Service {
  constructor() {
    super(RATE_VERFICATION_SERVICE_URL);
  }

  async getRateVerficationDetails(
    orderId?: string,
    mcNumberOrDOTNumber?: string,
    price?: string
  ) {
    try {
      const response = await this.axios.get<RateVerification>("", {
        params: { orderId, mcNumberOrDOTNumber, price }
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

const rateVerficationService = new RateVerficationService();

export default rateVerficationService;
