import { useQuery } from "react-query";
import RateVerficationService from "../../services/RateVerficationService";

export const useRateVerificationData = (
  orderId: string,
  mcNumberOrDOTNumber: string,
  price: string
) => {
  const rateVerficationDetails = useQuery(
    ["rateverification", orderId, mcNumberOrDOTNumber, price],
    () =>
      RateVerficationService.getRateVerficationDetails(
        orderId,
        mcNumberOrDOTNumber,
        price
      ),
    {
      staleTime: 1000,
      retry: 0,
      enabled: orderId !== undefined,
      refetchOnWindowFocus: false
    }
  );
  return {
    rateVerficationDetails: rateVerficationDetails.data,
    rateVerficationDetailsError: rateVerficationDetails.error,
    isLoadingRateVerficationDetails: rateVerficationDetails.isLoading
  };
};
