import { styled } from "shamrock-clover-ui";

interface FlexProps {
  alignItems?: "stretch" | "center" | "flex-start" | "flex-end";
  justifyContent?:
    | "stretch"
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
  flexDirection?: "row" | "row-reverse" | "column" | "column-reverse";
  flexWrap?: "nowrap" | "wrap" | "wrap-reverse";
  alignContent?: "stretch" | "center" | "flex-start" | "flex-end";
  height?: string;
  width?: string;
  margin?: string;
  padding?: string;
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  height: ${({ height }: FlexProps) => height && height};
  width: ${({ width }: FlexProps) => width && width};
  align-items: ${({ alignItems }: FlexProps) => alignItems && alignItems};
  justify-content: ${({ justifyContent }: FlexProps) =>
    justifyContent && justifyContent};
  flex-direction: ${({ flexDirection }: FlexProps) =>
    flexDirection && flexDirection};
  flex-wrap: ${({ flexWrap }: FlexProps) => flexWrap && flexWrap};
  align-content: ${({ alignContent }: FlexProps) =>
    alignContent && alignContent};
  margin: ${({ margin }: FlexProps) => margin && margin};
  padding: ${({ padding }: FlexProps) => padding && padding};
`;

interface FlexItemProps {
  flexGrow?: number;
  flexShrink?: number;
  alignSelf?: "auto" | "stretch" | "center" | "flex-start" | "flex-end";
  flex?: number;
}

export const FlexItem = styled(Flex as any)<FlexItemProps>`
  flex-grow: ${({ flexGrow }: FlexItemProps) => flexGrow && flexGrow};
  flex-shrink: ${({ flexShrink }: FlexItemProps) => flexShrink && flexShrink};
  align-self: ${({ alignSelf }: FlexItemProps) => alignSelf && alignSelf};
  flex: ${({ flex }: FlexItemProps) => flex && flex};
`;
