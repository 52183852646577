import axios, { AxiosInstance } from "axios";

export default class Service {
  axios: AxiosInstance;

  constructor(serviceUrl: string) {
    this.axios = axios.create({
      baseURL: serviceUrl
    });
  }
}