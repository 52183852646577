import React from "react";
import RateVerificationSearch from "./RateVerificationSearch";
import RateVerificationHeader from "./RateVerificationHeader";
import RateVerificationDetails from "./RateVerificationDetails";
import { useRateVerificationQueryParams } from "../../hooks/routeHooks";
import { Flex } from "../../components/Flex";

const RateVerification: React.FC<{}> = () => {
  const {
    queryParams: { orderId, mcNumberOrDOTNumber }
  } = useRateVerificationQueryParams();

  return (
    <Flex
      style={{ overflow: "auto" }}
      height="100%"
      width="100%"
      flexDirection="column"
    >
      {orderId && mcNumberOrDOTNumber ? (
        <>
          <RateVerificationHeader showInputs={true} />
          <RateVerificationDetails />
        </>
      ) : (
        <>
          <RateVerificationHeader showInputs={false} />
          <RateVerificationSearch />
        </>
      )}
    </Flex>
  );
};

export default RateVerification;
