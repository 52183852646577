import { createRoot } from "react-dom/client";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import * as firebaseUtils from "./utilites/firebaseUtils";

// features
import { CloverThemeProvider } from "shamrock-clover-ui";
// styles

import "./index.css";
import "shamrock-clover-ui/dist/clover/css/clover.css";
import reportWebVitals from "./reportWebVitals";
import React from "react";

const queryClient = new QueryClient();

firebaseUtils
  .init({

    apiKey: "AIzaSyAnfAhdvyuTNmrhcik20-zsbplwkD2_jrI",
    authDomain: "carrierpro-144918.firebaseapp.com",
    databaseURL: "https://carrierpro-144918.firebaseio.com",
    projectId: "carrierpro-144918",
    storageBucket: "carrierpro-144918.appspot.com",
    messagingSenderId: "715693729194",
    appId: "1:715693729194:web:9ddbb35da0c9750d67b7d3",
    measurementId: "G-Y1EFMEJ7NL"
  })
  .catch(error => {
    console.log(error.message);
    //log sentry error
  });

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(

  <BrowserRouter>
    <CloverThemeProvider>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </QueryClientProvider>
    </CloverThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
