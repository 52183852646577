import React, { PropsWithChildren } from "react";
import RTSProLogo from "../../assets/images/rtspro-logo-blue.svg";
import { styled } from "shamrock-clover-ui";
import cloverTheme from "shamrock-clover-ui/dist/clover/Theme/Theme";
import "./appHeader.scss";

export const LoginLink = styled.a`
  font-size: ${(theme: typeof cloverTheme) => theme.font.size.small};
  color: #666666;
  align-self: center;
  margin-left: auto;
`;

const Header: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="header">
      <div className="appBarTopMenu">
        <div className="toolBar">
          <div className="rightSideHeader"></div>
        </div>
      </div>
      <div className="appBarPrimaryNavigation">
        <div className="toolBar">
          <img alt="rts-logo" src={RTSProLogo} className="logo" />
          {children}
        </div>
      </div>
    </div>
  );
};

export default Header;
