import React from "react";
import QuestionMark from "../../assets/images/QuestionMark.svg";
import RateVerificationInputs from "./RateVerificationInputs";
import RateVerificationFooter from "./RateVerificationFooter";
import {
  SearchContainer,
  StatusSecoundaryText,
  StatusTextBold,
  Icon,
  ParentContainer,
  SignUp,
  ErrorOrderNumberText
} from "./RateVerificationStyles";
import { Flex } from "../../components/Flex";

export const RateVerficationNoMatches = () => {
  return (
    <SearchContainer>
      <ParentContainer flexDirection="column">
        <Flex flexDirection="column" style={{ padding: "40px" }} width="100%">
          <StatusTextBold>
            <Icon src={QuestionMark} />
          </StatusTextBold>
          <ErrorOrderNumberText>
            No loads found, please call (877) 519-1984
          </ErrorOrderNumberText>
          <StatusSecoundaryText>
            Try entering your MC Number with or without the prefix zeros (e.g.,
            00032156 or 32156)
          </StatusSecoundaryText>
          <RateVerificationInputs />
        </Flex>
        <SignUp flexDirection="column" alignItems="center">
          <RateVerificationFooter />
        </SignUp>
      </ParentContainer>
    </SearchContainer>
  );
};

export default RateVerficationNoMatches;
