import React from "react";
import { Flex } from "../../components/Flex";
import {
  DetailsContainer,
  OrderIdText,
  LoadStatusText,
  LoadErrorText,
  HeaderDetailContainer,
  ReferenceHeaderText,
  LeftText,
  RightText,
  ParentContainer
} from "./RateVerificationStyles";
import InTransit from "../../assets/images/InTransit.svg";
import RateVerificationFooter from "./RateVerificationFooter";
import { useRateVerificationQueryParams } from "../../hooks/routeHooks";
import { useRateVerificationData } from "./useRateVerificationData";
import { monthDayFormat } from "../../utilites/dateHelper";
import {
  BILLING_EMAIL_LINK,
  BILLING_EMAIL_LINK_TEXT
} from "../../utilites/constants";
import { Spinner } from "shamrock-clover-ui";
import RateVerficationNoMatches from "./RateVerficationNoMatches";

const RateVerificationDetails: React.FC = () => {
  const { queryParams } = useRateVerificationQueryParams();
  const {
    rateVerficationDetails,
    isLoadingRateVerficationDetails,
    rateVerficationDetailsError
  } = useRateVerificationData(
    queryParams.orderId,
    queryParams.mcNumberOrDOTNumber,
    queryParams.price
  );

  const formatCurrency = (value: number) => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  };

  const handleNull = (
    value: number | string | undefined,
    isCurrency?: boolean
  ) => {
    if (value === undefined) return "---";
    if (isCurrency && typeof value === "number") return formatCurrency(value);
    else return value;
  };

  const calculateNetPay = (total: number, advance: number) => {
    return formatCurrency(total - advance);
  };

  if (rateVerficationDetailsError || rateVerficationDetails === null) {
    return <RateVerficationNoMatches />;
  }

  return (
    <ParentContainer>
      <Flex height="450px" justifyContent="center">
        <Flex>
          {isLoadingRateVerficationDetails ? (
            <Flex
              height="377px"
              width="50vw"
              justifyContent="center"
              alignItems="center"
            >
              {" "}
              <Spinner />{" "}
            </Flex>
          ) : (
            <>
              <DetailsContainer
                width="50vw"
                flexDirection="column"
                alignItems="center"
                isFullDetails={false}
              >
                <HeaderDetailContainer
                  alignItems="center"
                  padding="24px 24px 24px 54px"
                >
                  <img
                    alt="in-transit"
                    src={InTransit}
                    style={{ marginRight: "18px" }}
                  />
                  <Flex flexDirection="column">
                    <OrderIdText>Order {queryParams.orderId}</OrderIdText>
                    <LoadStatusText>
                      {rateVerficationDetails?.checkNumber &&
                      rateVerficationDetails?.payDate
                        ? "Paid"
                        : "Unpaid"}
                    </LoadStatusText>
                    <LoadErrorText>
                      {rateVerficationDetails?.freezePay ? (
                        "Payment delayed, please call (877) 519-1984, ext. 2 for additional information."
                      ) : (
                        <></>
                      )}
                    </LoadErrorText>
                  </Flex>
                </HeaderDetailContainer>
                <Flex padding="0px 26px 0px 54px">
                  <Flex flexDirection="column" width="100%" padding="18px 0px">
                    <ReferenceHeaderText>Payment Details</ReferenceHeaderText>
                    <Flex padding="1.5px 0px">
                      <LeftText>CARRIER NAME</LeftText>
                      <RightText>
                        {handleNull(rateVerficationDetails?.companyName)}
                      </RightText>
                    </Flex>
                    <Flex padding="1.5px 0px">
                      <LeftText>CARRIER MC NUMBER</LeftText>
                      <RightText>
                        {handleNull(rateVerficationDetails?.mcNumber)}
                      </RightText>
                    </Flex>
                    <Flex padding="1.5px 0px">
                      <LeftText>PAY TO</LeftText>
                      <RightText>
                        {handleNull(
                          rateVerficationDetails?.isFactored
                            ? rateVerficationDetails?.factoringCompanyName
                            : rateVerficationDetails?.companyName
                        )}
                      </RightText>
                    </Flex>
                    {rateVerficationDetails?.checkNumber && (
                      <Flex padding="1.5px 0px">
                        <LeftText>CHECK NUMBER</LeftText>
                        <RightText>
                          {handleNull(rateVerficationDetails?.checkNumber)}
                        </RightText>
                      </Flex>
                    )}
                    <Flex padding="1.5px 0px">
                      <LeftText>PAYMENT DATE </LeftText>
                      {rateVerficationDetails?.checkNumber &&
                      rateVerficationDetails?.payDate ? (
                        <RightText>
                          {monthDayFormat(rateVerficationDetails?.payDate)}
                        </RightText>
                      ) : (
                        <RightText>
                          {rateVerficationDetails?.okToPayDate
                            ? monthDayFormat(
                                rateVerficationDetails?.okToPayDate
                              ) + " (Estimated)"
                            : "---"}
                        </RightText>
                      )}
                    </Flex>
                    <Flex padding="1.5px 0px">
                      <LeftText>TOTAL PAY</LeftText>
                      <RightText>
                        {handleNull(rateVerficationDetails?.totalPay, true)}
                      </RightText>
                    </Flex>
                    <Flex padding="1.5px 0px">
                      <LeftText>ADVANCES</LeftText>
                      <RightText>
                        {handleNull(rateVerficationDetails?.totalAdvance, true)}
                      </RightText>
                    </Flex>
                    <Flex padding="1.5px 0px">
                      <LeftText>NET PAY</LeftText>
                      <RightText>
                        {calculateNetPay(
                          rateVerficationDetails?.totalPay
                            ? rateVerficationDetails?.totalPay
                            : 0,
                          rateVerficationDetails?.totalAdvance
                            ? rateVerficationDetails?.totalAdvance
                            : 0
                        )}
                      </RightText>
                    </Flex>
                    {rateVerficationDetails?.isMissingImages && (
                      <Flex padding="1.5px 0px">
                        <LeftText>NOTES</LeftText>
                        <RightText>
                          {"Missing paperwork. Please email to "}
                          <a href={BILLING_EMAIL_LINK}>
                            {BILLING_EMAIL_LINK_TEXT}
                          </a>
                        </RightText>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </DetailsContainer>
            </>
          )}
        </Flex>
      </Flex>
      <RateVerificationFooter />
    </ParentContainer>
  );
};

export default RateVerificationDetails;
