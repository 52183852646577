import * as React from "react";
import {
  Footer,
  FooterLink,
  FooterSeperator,
  PhoneLink
} from "./RateVerificationStyles";

const RateVerificationFooter: React.FC = () => {
  return (
    <>
      <Footer>
        <p>
          © Ryan Transportation Service, Inc. All rights reserved
          <FooterSeperator>|</FooterSeperator>
          <FooterLink href="https://www.ryantrans.com/page/terms-use">
            Terms of use
          </FooterLink>
          <FooterSeperator>|</FooterSeperator>
          <FooterLink href="https://www.ryantrans.com/page/privacy-policy">
            Privacy Policy
          </FooterLink>
          <FooterSeperator>|</FooterSeperator>
          <FooterLink href="https://www.ryantrans.com/page/customer-portal-terms-of-use">
            Terms and Conditions
          </FooterLink>
          <FooterSeperator>|</FooterSeperator>
          <PhoneLink>877-519-1984</PhoneLink>
        </p>
      </Footer>
    </>
  );
};

export default RateVerificationFooter;
