import React, { useState, ChangeEvent, FocusEvent } from "react";
import { useRateVerificationQueryParams } from "../../hooks/routeHooks";
import {
  FilterContainer,
  FilterInputContainer,
  Filters,
  SearchButton,
  ResetButton,
  LocationFilterContainer,
  StyledFilterInput,
  StyledFilterCurrencyInput
} from "./RateVerificationStyles";
import * as FirebaseUtils from "../../utilites/firebaseUtils";
import { Icon } from "shamrock-clover-ui";

export const RateVerificationInputs: React.FC<{}> = () => {
  const { setQueryParams, queryParams } = useRateVerificationQueryParams();

  const [orderNumber, setOrderNumer] = useState(queryParams.orderId || "");
  const [mcNumberOrDOTNumber, setMcNumberOrDOTNumber] = useState(
    queryParams.mcNumberOrDOTNumber || ""
  );
  const [priceNumber, setPriceNumber] = useState(queryParams.price || "");

  const searchForRateVerfication = () => {
    FirebaseUtils.logFirebaseEvent(
      FirebaseUtils.FirebaseEvents.CLICK,
      FirebaseUtils.FirebaseModules.PROVERIFY,
      FirebaseUtils.FirebasePages.SEARCH,
      {
        orderNumber: orderNumber,
        mcNumberOrDOTNumber: mcNumberOrDOTNumber,
        price: priceNumber,
        description: "search"
      }
    );

    setQueryParams({
      orderId: orderNumber,
      mcNumberOrDOTNumber: mcNumberOrDOTNumber,
      price: priceNumber
    });
  };

  const isDisabled = !orderNumber || !mcNumberOrDOTNumber || !priceNumber;

  return (
    <FilterContainer>
      <Filters>
        <FilterInputContainer>
          <StyledFilterInput
            placeholder="Order Number"
            value={orderNumber}
            clearSearch={() => setOrderNumer("")}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setOrderNumer(event.target.value);
            }}
          />
        </FilterInputContainer>
        <LocationFilterContainer>
          <StyledFilterInput
            placeholder="Carrier MC or DOT Number"
            value={mcNumberOrDOTNumber}
            clearSearch={() => setMcNumberOrDOTNumber("")}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setMcNumberOrDOTNumber(event.target.value);
            }}
          />
        </LocationFilterContainer>
        <StyledFilterCurrencyInput
          placeholder="Price"
          value={priceNumber}
          type="number"
          clearSearch={() => setPriceNumber("")}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            if (event.target.value.match(/\./g)) {
              const [, decimal] = event.target.value.split(".");
              // restrict value to only 2 decimal places
              if (decimal?.length > 2) {
                // do nothing
                return;
              }
            }
            setPriceNumber(event.target.value);
          }}
          onBlur={(event: FocusEvent<HTMLInputElement>) => {
            setPriceNumber(parseFloat(event.target.value).toFixed(2));
          }}
        />
        <SearchButton
          type="button"
          data-testid="SearchButton"
          onClick={() => searchForRateVerfication()}
          disabled={isDisabled}
        >
          <Icon icon="searchWhite" size="18" />
        </SearchButton>
        <ResetButton
          onClick={() => {
            setOrderNumer("");
            setMcNumberOrDOTNumber("");
            setPriceNumber("");
          }}
          disabled={isDisabled}
        >
          Reset Search
        </ResetButton>
      </Filters>
    </FilterContainer>
  );
};

export default RateVerificationInputs;
