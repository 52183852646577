import { styled, Button, FilterInput } from "shamrock-clover-ui";
import { Flex } from "../../components/Flex";

export const DetailsContainer = styled.div<{ isFullDetails: boolean }>`
  background-color: white;
  margin: auto;
  width: 580px;
  min-height: 360px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 2px 0px,
    rgba(0, 0, 0, 0.24) 0px 2px 2px 0px;
`;

export const OrderIdText = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 900;
`;

export const LastKnownText = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: 900;
`;

export const LoadStatusText = styled.div`
  color: "#000000";
  font-size: 12px;
  font-weight: 900;
`;

export const LoadErrorText = styled.div`
  color: "#EF3824";
  font-size: 12px;
  padding-right: 60px;
`;

export const HeaderDetailContainer = styled(Flex as any)`
  border-bottom: 1px solid #dddddd;
`;

export const ReferenceContainer = styled(Flex as any)`
  border-bottom: 1px solid #dddddd;
`;

export const ReferenceHeaderText = styled.div`
  color: #000000;
  font-size: 16px;
  font-weight: 900;
  padding-bottom: 10px;
`;

export const LeftText = styled.div`
  font-weight: 400;
  text-align: left;
  box-sizing: border-box;
  width: 175px;
  color: #424242;
  font-family: "proxima-nova";
  font-size: 13px;
  letter-spacing: -0.06px;
  line-height: 16px;
  text-transform: uppercase;
`;

export const RightText = styled.div`
  text-align: left;
  box-sizing: border-box;
  left: 220px;
  color: #424242;
  font-family: "proxima-nova";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.06px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
`;

export const Filters = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const SearchButton = styled(Button as any)`
  border-radius: 24px;
  border: 0;
  background-color: #0091ea;
  color: white;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  :focus {
    outline: 0;
  }
  :disabled {
    background-color: #eeeeee;
  }
`;

export const ResetButton = styled.button`
  border: none;
  padding-left: 16px;
  background: none;
  color: #0091ea;
  height: 36px;
  border-left: #d8d8d8 1px solid;
  :focus {
    outline: 0;
  }
  :disabled {
    color: #d0d0d0;
  }
`;

export const LocationFilterContainer = styled.div`
  .react-autosuggest__container {
    max-width: 330px;
    min-width: 250px;
    height: 40px;
    margin-right: 16px;
  }

  .react-autosuggest__input {
    border-radius: 24px;
    border: none;
    outline: 0;
    height: 40px;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 330px;
    min-width: 250px;
    background-color: #eeeeee;
    ::-webkit-input-placeholder {
      color: #999999;
    }
  }
  .react-autosuggest__suggestions-container--open {
    background-color: white;
    position: absolute;
    z-index: 1;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .react-autosuggest__suggestion {
    display: block;
    width: 200px;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    cursor: pointer;
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: #cccccc;
  }

  .react-autosuggest__suggestions-list {
    padding-inline-start: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const FilterInputContainer = styled.div`
  & > :nth-of-type(1) {
    width: 175px;
    max-width: unset;
    margin-right: 16px;
  }
`;

export const StyledFilterInput = styled(FilterInput as any)`
  background-color: #eeeeee;
  border: none;
  max-width: 330px;
  margin-right: 20px;
`;

export const StyledFilterCurrencyInput = styled(FilterInput as any)`
  background-color: #eeeeee;
  border: none;
  max-width: 330px;
  margin-right: 20px;
  :before {
    content: "$";
    padding-right: 10px;
  }
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const StatusText = styled(Flex as any)`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 18px;
  color: #888888;
  font-family: "proxima-nova";
`;

export const StatusSecoundaryText = styled(StatusText as any)`
  padding-bottom: 20px;
`;

export const LeadsSecoundaryText = styled(Flex as any)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: #888888;
  font-family: "proxima-nova";
  font-size: 1.1em !important;
  line-height: 1.6em !important;
  font-weight: 400 !important;
`;

export const StatusTextBold = styled(StatusText as any)`
  padding-bottom: 20px;
  font-family: "proxima-nova";
  color: #000;
  font-weight: 500;
`;

export const ErrorOrderNumberText = styled(StatusTextBold as any)`
  font-weight: 900 !important;
  color: rgb(136, 136, 136) !important;
  padding-bottom: 5px !important;
`;

export const OrderNumberText = styled(StatusTextBold as any)`
  font-weight: 900 !important;
  color: rgb(136, 136, 136) !important;
  padding-bottom: 20px !important;
`;

export const LeadsPrimaryText = styled(StatusText as any)`
  margin-top: 30px;
  padding-top: 10px;
  font-size: 2.5em !important;
  line-height: 1.2em !important;
  font-weight: bold !important;
  letter-spacing: -0.75px;
  color: #000 !important;
  font-family: "proxima-nova", sans-serif !important;
  align-items: flex-start;
  text-align: left;
  justify-content: flex-start !important;
  font-family: "proxima-nova";
`;

export const SmallIcon = styled.img`
  height: 70px;
  width: 70px;
`;

export const Icon = styled.img`
  height: 120px;
  width: 120px;
`;

export const SignUp = styled(Flex as any)`
  width: 100%;
  font-size: 18px;
  font-weight: 900;
  color: #888888;
  margin-top: auto;
`;

export const ParentContainer = styled(Flex as any)`
  height: 93%;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  padding-top: 20px;
`;

export const StyledButton = styled(Button as any)`
  font-size: 12px;
  margin-top: 24px;
`;

export const LastKnownLocationTextContainer = styled(Flex as any)`
  text-align: right;
`;

export const Footer = styled.div`
  flex-shrink: 0;
  height: 15px;
  width: 100%;
  color: #888888;
  font-family: "proxima-nova";
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  justify-content: center;
  padding-top: 20px;
  a:hover {
    color: #666666;
  }
`;

export const FooterSeperator = styled.span`
  padding-left: 10px;
  padding-right: 10px;
`;

export const PhoneLink = styled.a`
  color: #0083ca;
`;

export const FooterLink = styled.a`
  color: #888888;
`;
