import queryString from "query-string";
import { useLocation, useHistory } from "react-router";

const useQueryParams = <T extends {}>() => {
  const { search } = useLocation();
  const { push } = useHistory();

  const addQueryParams = (paramsToAdd: T) => {
    const query = queryString.parse(window.location.search);

    push({
      search: queryString.stringify(
        {
          ...query,
          ...paramsToAdd
        },
        { arrayFormat: "comma" }
      )
    });
  };

  const clearQueryParams = () => {
    push({
      search: ""
    });
  };

  const setQueryParams = (paramsToAdd: T) => {
    push({
      search: queryString.stringify(
        {
          ...paramsToAdd
        },
        { arrayFormat: "comma" }
      )
    });
  };

  return {
    addQueryParams,
    clearQueryParams,
    setQueryParams,
    queryParams: queryString.parse(search, { arrayFormat: "comma" }) as T
  };
};

interface RateVerificationParams {
  orderId: string;
  mcNumberOrDOTNumber: string;
  price: string;
}

export const useRateVerificationQueryParams = () => {
  return useQueryParams<RateVerificationParams>();
};
