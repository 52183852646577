// vendors
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { logFirebaseUser } from "./utilites/firebaseUtils";

// styles
import "./styles/main.scss";

// features
import { RateVerification } from "./features/rateVerification";

function App() {

  useEffect(()=>{
    logFirebaseUser();
  }, []);

  return (
    <Router>
      <Route path="/" component={RateVerification} exact />
    </Router>
  );
}

export default App;
