import React from "react";
import InTransitGray from "../../assets/images/InTransitGray.svg";
import RateVerificationInputs from "./RateVerificationInputs";
import RateVerificationFooter from "./RateVerificationFooter";
import {
  SearchContainer,
  StatusTextBold,
  Icon,
  ParentContainer,
  SignUp,
  OrderNumberText
} from "./RateVerificationStyles";
import { Flex } from "../../components/Flex";

export const RateVerificationSearch = () => {
  return (
    <SearchContainer>
      <ParentContainer flexDirection="column">
        <Flex flexDirection="column" style={{ padding: "40px" }} width="100%">
          <StatusTextBold>
            <Icon src={InTransitGray} />
          </StatusTextBold>
          <OrderNumberText>
            To verify rates, enter an Order Number and the MC / DOT Number of
            the carrier that hauled the load.
          </OrderNumberText>
          <RateVerificationInputs />
        </Flex>
        <SignUp flexDirection="column" alignItems="center">
          <RateVerificationFooter />
        </SignUp>
      </ParentContainer>
    </SearchContainer>
  );
};

export default RateVerificationSearch;
