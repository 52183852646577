import React from "react";
import RateVerificationInputs from "./RateVerificationInputs";
import AppHeader from "../../components/appHeader/AppHeader";
import { useRateVerificationQueryParams } from "../../hooks/routeHooks";
import { useRateVerificationData } from "./useRateVerificationData";
interface Props {
  showInputs: boolean;
}

export const DetailHeader: React.FC<Props> = ({ showInputs }) => {
  const { queryParams } = useRateVerificationQueryParams();

  const {
    rateVerficationDetails,
    rateVerficationDetailsError
  } = useRateVerificationData(
    queryParams.orderId,
    queryParams.mcNumberOrDOTNumber,
    queryParams.price
  );

  return (
    <AppHeader>
      {showInputs &&
        !rateVerficationDetailsError &&
        rateVerficationDetails !== null && <RateVerificationInputs />}
    </AppHeader>
  );
};

export default DetailHeader;
